import { action, observable } from 'mobx'

export class AppState {

  @observable updateAvailable = false

  @action
  setUpdateAvailable = () => {
    this.updateAvailable = true
  }
}

export const appState = new AppState()
